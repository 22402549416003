import React from "react";
import Login from "./pages/Login";
import NewPassword from "./pages/NewPassword";
import RecoverPassword from "./pages/RecoverPassword";
import { LoginProvider } from "./contexts/LoginContext";
import { Switch, Route, HashRouter } from "react-router-dom";
import { RecoverProvider } from "./contexts/RecoverContext";

function App() {
  return (
    <HashRouter>
      <Switch>
        <RecoverProvider>
          <LoginProvider>
            <Route path="/" exact component={Login} />
            <Route path="/forgot-password" exact component={RecoverPassword} />
            <Route path="/update-password" exact component={NewPassword} />
          </LoginProvider>
        </RecoverProvider>
      </Switch>
    </HashRouter>
  );
}

export default App;
