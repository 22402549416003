import React, { FormEvent, useEffect, useState } from "react";
import {
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  Button,
  FormGroup,
  Label,
  Form,
  Spinner,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faKey,
  // faCamera,
  // faMicrophone,
} from "@fortawesome/free-solid-svg-icons";

import "./../assets/css/login.scss";

import { useLogin } from "../contexts/LoginContext";
import { Link } from "react-router-dom";
import BoxTitle from "../components/BoxTitle";
import BackgroundLogin from "../components/BackgroundLogin";
import { sendNotification } from "../util/notification";

const Login: React.FC = () => {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [save, setSave] = useState(true);
  const { setLoginUser, loading } = useLogin();
  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoginUser({ login, password, save });
  };
  useEffect(() => {
    if (sessionStorage.getItem("auth-error") === "true") {
      sessionStorage.removeItem("auth-error");
      sendNotification("Error", "Por favor, faça login novamente!", "danger");
    }
  }, []);
  return (
    <BackgroundLogin>
      <div className="row w-100">
        <div className="col-12 col-lg-6 d-flex justify-content-center justify-content-lg-end align-items-center">
          <div className="card card-login mr-lg-5">
            <div className="card-body m-3 m-lg-5 has-shown-squad">
              <div className="d-flex flex-column align-items-center">
                <div className="mb-4">
                  <img
                    style={{ maxWidth: "200px" }}
                    src="./logo.png"
                    alt="Logo"
                  />
                </div>
                <div className="mb-3 title">Seja bem-vindo(a)!</div>
                <div className="mb-4 sub-title">
                  Digite os seus dados para acessar:
                </div>
              </div>
              <Form onSubmit={onSubmit}>
                <div className="mb-3">
                  <InputGroup>
                    <Input
                      value={login}
                      onChange={(e) => {
                        console.log(e);
                        setLogin(e.target.value);
                      }}
                      type="email"
                      placeholder="Nome do usuário"
                      bsSize="lg"
                      className="input-login"
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText className="input-group-text">
                        <FontAwesomeIcon
                          icon={faEnvelope}
                          size="sm"
                          className="input-icon"
                        />
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </div>
                <div className="mb-4">
                  <InputGroup>
                    <Input
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      type="password"
                      placeholder="Senha"
                      bsSize="lg"
                      className="input-login"
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText className="input-group-text">
                        <FontAwesomeIcon
                          icon={faKey}
                          size="sm"
                          className="input-icon"
                        />
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </div>
                <div className="input-options">
                  <div className="row mb-3">
                    <div className="col">
                      <FormGroup check>
                        <Label check>
                          <div className="form-check-input-box">
                            <Input
                              type="checkbox"
                              checked={save}
                              onChange={() => setSave(!save)}
                            />{" "}
                          </div>
                          <span>Salvar senha</span>
                        </Label>
                      </FormGroup>
                    </div>
                    <div className="col d-flex justify-content-end">
                      <FormGroup check>
                        <Label check>
                          <Link to="/forgot-password" className="link-password">
                            Esqueceu a senha?
                          </Link>
                        </Label>
                      </FormGroup>
                    </div>
                  </div>
                  {/* <div className="row mb-4">
                    <div className="col">
                      <FormGroup check>
                        <Label check>
                          <div className="form-check-input-box">
                            <Input type="checkbox" />{" "}
                          </div>
                          <FontAwesomeIcon icon={faCamera} size="1x" />{" "}
                          Desativar a câmera
                        </Label>
                      </FormGroup>
                    </div>
                    <div className="col d-flex justify-content-end">
                      <FormGroup check>
                        <Label check>
                          <div className="form-check-input-box">
                            <Input type="checkbox" />{" "}
                          </div>
                          <FontAwesomeIcon icon={faMicrophone} size="1x" />{" "}
                          Desativar microfone
                        </Label>
                      </FormGroup>
                    </div>
                  </div> */}
                </div>
                <div>
                  <Button
                    type="submit"
                    size="lg"
                    block
                    className="buttom-login"
                    style={{
                      height: "54px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {!loading ? "Entrar" : <Spinner size="md"></Spinner>}
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
        <BoxTitle />
      </div>
    </BackgroundLogin>
  );
};

export default Login;
