import React, { useState } from "react";
import {
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  Button,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

import "./../assets/css/login.scss";

import BoxTitle from "../components/BoxTitle";
import BackgroundLogin from "../components/BackgroundLogin";
import { useRecover } from "../contexts/RecoverContext";
import { Link } from "react-router-dom";

const RecoverPassword: React.FC = () => {
  const { setRecoverUser } = useRecover();
  const [email, setEmail] = useState<string>();
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setRecoverUser(email);
  };
  return (
    <BackgroundLogin>
      <div className="row w-100">
        <div className="col-12 col-lg-6 d-flex justify-content-center justify-content-lg-end align-items-center">
          <div className="card card-login mr-lg-5">
            <div className="card-body m-3 m-lg-5">
              <div className="d-flex flex-column align-items-center">
                <div className="mb-4">
                  <img
                    style={{ maxWidth: "200px" }}
                    src="./logo.png"
                    alt="Logo"
                  />
                </div>
                <div className="mb-3 title">Recuperação de senha.</div>
                <div className="mb-4 sub-title">
                  Digite seu Login para recuperar a senha:
                </div>
              </div>
              <form onSubmit={onSubmit}>
                <div className="mb-3">
                  <InputGroup>
                    <Input
                      type="email"
                      placeholder="Login"
                      bsSize="lg"
                      className="input-login"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText className="input-group-text">
                        <FontAwesomeIcon
                          icon={faEnvelope}
                          size="sm"
                          className="input-icon"
                        />
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </div>
                <div>
                  <Link className="text-decoration-none" to="/">
                    <Button
                      type="button"
                      className="button-login-shape"
                      color="secondary"
                      size="lg"
                      block
                    >
                      Voltar
                    </Button>
                  </Link>
                  <Button
                    type="submit"
                    size="lg"
                    block
                    className="buttom-login mt-2"
                  >
                    Recuperar
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <BoxTitle />
      </div>
    </BackgroundLogin>
  );
};

export default RecoverPassword;
