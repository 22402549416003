import axios, { AxiosInstance } from 'axios'
import clientData from './constants/clientData'
import * as routes from './constants/routes'
import {
  ApiOptions,
  Contact,
  Group,
  ParamsURL,
  AuthReturn,
  GetChatsCallback,
  GetContactsCallback,
  GetGroupsCallback,
  InstanceSettings,
  AuthSettings,
  Chat,
  GetSettingsCallback,
  CreateGroupCallback,
  GetGroupCallback,
  GetMessagesCallback,
  FavoriteUser,
  FavoriteUserCallback,
  GetAttachmentsCallback,
  UserDataChange,
  UserDataChangeCallback,
  UserPasswordChange,
  UserPasswordChangeCallback,
  GetUserInfoCallback,
  RemoveContactRequest,
  RemoveContactCallback,
  LeaveGroupRequest,
  LeaveGroupCallback,
  RemoveMemberCallback,
  RemoveMemberRequest,
  AddContactCallback,
  AddContactRequest,
  AddMemberCallback,
  AddMemberRequest,
  toggleMemberRoleRequest,
  toggleMemberRoleCallback,
  ChangeGroupInfoRequest,
  ChangeGroupInfoCallback,
  GetConferencesCallback,
  ConferenceRoom,
  CreateConferenceRequest,
  CreateConferenceCallback,
  ConferenceRoomParticipant,
  AddConferenceParticipantCallback,
  RemoveConferenceParticipantCallback,
  SendConferenceParticipantEmailCallback,
  Conferences,
  UpdateConferenceCallback,
  SaveSharedDataRequest,
  SaveSharedDataCallback,
  FavoriteMessagePayload,
  FavoriteMessageCallback,
  ListFavoriteMessagesCallback,
  ListFavoriteMessagesPayload,
  DeleteMessagePayload,
  DeleteMessageCallback,
  GetScheduleContactsCallback,
  GetCallsHistoryCallback,
  ScheduleContactPayload,
  CreateScheduleContactCallback,
  RemoveConferenceCallback,
  ReadMessageCallback,
  GetRamalInfoCallback,
  RamalFacilities,
  UpdateRamalFacilitiesCallback,
  GetRamalFacilitiesCallback,
  CallGroupConferenceParticipants,
  GetGroupConferenceParticipants,
  changePictureCallback,
  DeviceSettings
} from './types/types'
import { parseMsg, validateEmail } from './util/util'
export default interface API {
  api: AxiosInstance
}

export default class API {
  constructor({ apiUrl, token }: InstanceSettings) {
    this.api = axios.create({
      baseURL: apiUrl,
      timeout: 30000,
      headers: { Authorization: `Bearer ${token}` }
    })
  }

  async getApi() {
    return this.api({
      method: 'GET',
      url: `${routes.CONTACTS}/me`
    })
  }

  static async getApiUrl(login: string) {
    try {
      const API_URL = (await axios.get(routes.DPS, { params: { domain: login.split('@')[1] } }))
        .data?.api_url
      if (!API_URL) {
        throw new Error('Dominio não encontrado')
      }
      return API_URL
    } catch (e) {}
    return undefined
  }
  static async auth({ login, password }: AuthSettings): Promise<AuthReturn | undefined> {
    if (!validateEmail(login)) {
      throw new Error('E-mail inválido!')
    }
    let API_URL = await API.getApiUrl(login)
    let url = API_URL + routes.OAUTH
    const params = {
      grant_type: 'password',
      client_id: clientData.client_id,
      client_secret: clientData.client_secret,
      username: login,
      password: password
    }
    let returnData
    let returnJson
    url +=
      '?' +
      Object.entries(params)
        .map(([key, val]) => `${key}=${val}`)
        .join('&')
    try {
      returnData = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Accept: 'application/json'
        }
      })
      returnJson = await returnData.json()
    } catch (e) {
      console.log(e)
    }
    switch (returnData?.status) {
      case 200:
        if (returnJson) return { ...returnJson, baseUrl: API_URL }
        else throw new Error('Sem resposta!')
      case 400:
        throw new Error('Algo de errado aconteceu, contate o suporte!')
      case 401:
        throw new Error('Usuário ou senha inválido!')
      case 403:
        throw new Error('Usuário ou senha inválido!')
      case 500:
        throw new Error('Algo de errado aconteceu, contate o suporte!')
      default:
        throw new Error('Algo de errado aconteceu, contate o suporte!')
    }
  }
  static sendRecoverEmail = async (email: string) => {
    const apiUrl = await API.getApiUrl(email)
    const recoverResult = await axios({
      method: 'GET',
      url: `${apiUrl}${routes.RECOVER}/${email}`
    })

    if (recoverResult.status === 200) {
      return apiUrl
    } else if (recoverResult.status === 404) {
      throw new Error('Login não encontrado')
    } else if (recoverResult.status === 500) {
      throw new Error('Serviço indisponivel. Por favor, tente mais tarde.')
    } else {
      throw new Error('Erro inesperado')
    }
  }
  static updatePassword = async (apiUrl: string, token: string, password: string) => {
    const updatePassword = await axios({
      method: 'PUT',
      url: `${apiUrl}${routes.UPDATE_PASSWORD}`,
      data: {
        token,
        password
      }
    })
    if (updatePassword.status === 200) {
      return true
    } else if (updatePassword.status === 404) {
      throw new Error('Login não encontrado')
    } else if (updatePassword.status === 500) {
      throw new Error('Serviço indisponivel. Por favor, tente mais tarde.')
    } else {
      throw new Error('Erro inesperado')
    }
  }
  getSettings = (options: ApiOptions, callback: GetSettingsCallback) => {
    this.api
      .get(routes.SETTINGS)
      .then(response => {
        callback(response.data)
      })
      .catch(e => callback(null, e))
  }
  getUserInfo = (callback: GetUserInfoCallback) => {
    this.api({
      method: 'GET',
      url: `${routes.CONTACTS}/me`
    })
      .then(res => {
        callback(res.data)
      })
      .catch(e => {
        callback(null, e)
      })
  }
  getMessages = (
    chatId: string,
    chatType: string,
    options: ApiOptions,
    callback: GetMessagesCallback
  ) => {
    this.api({
      method: 'GET',
      url: `${routes.MESSAGES}/${chatType}/${chatId}`,
      params: options.paramsUrl
    })
      .then(response => {
        const parsedMessages = response.data.messages.map((message: any) => {
          return parseMsg(message)
        })
        callback({
          metadata: response.data.metadata,
          messages: parsedMessages
        })
      })
      .catch(e => callback(null, e))
  }
  getChats = (options: ApiOptions, callback: GetChatsCallback) => {
    this.api({ method: 'GET', url: routes.CHATS, params: options.paramsUrl }).then(response => {
      try {
        const parsedChats: Chat[] = response.data.chatList.map((chat: any) => {
          return {
            id: chat.user,
            name: chat.name,
            unreadMessages: chat.unreadMessages,
            chatType: chat.chatType,
            messages: [parseMsg(chat.lastMessage)]
          }
        })
        callback({
          metadata: response.data.metadata,
          chats: parsedChats
        })
      } catch (e) {
        callback(null, e)
      }
    })
  }

  getContacts = (options: ApiOptions, callback: GetContactsCallback) => {
    this.api({ method: 'GET', url: routes.CONTACTS, params: options.paramsUrl })
      .then(response => {
        try {
          const parsedContacts: Contact[] = response.data.contacts.map((contact: any) => {
            return {
              id: contact.id,
              name: contact.name,
              nickname: contact.nickname,
              imId: contact.imId,
              extensionId: contact.extensionId,
              extension: contact.extension,
              contactType: contact.contactType,
              email: contact.email,
              domainId: contact.domainId,
              domainName: contact.domainName,
              departmentId: contact.departmentId,
              department: contact.department,
              privacy: contact.privacy,
              blocked: contact.blocked,
              favorite: contact.favorite,
              admin: contact.admin,
              added: contact.added,
              status: contact.status,
              imageUrl: contact.imageUrl
            }
          })
          callback({
            metadata: response.data.metadata,
            contacts: parsedContacts
          })
        } catch (e) {
          callback(null, e)
        }
      })
      .catch(e => {
        callback(null, e)
      })
  }
  addContact = (payload: AddContactRequest, callback: AddContactCallback) => {
    this.api({
      method: 'PUT',
      url: `${routes.CONTACTS}/${payload.contactType}/${payload.contactId}`,
      data: { favorite: payload.favorite, blocked: payload.blocked }
    }).then(res => {
      if (res.status === 200) {
        callback(true)
      } else {
        callback(false)
      }
    })
  }

  addMember = (payload: AddMemberRequest, callback: AddMemberCallback) => {
    this.api({
      method: 'POST',
      url: `${routes.GROUP_CHATS}/${payload.groupId}/members/${payload.memberId}`
    }).then(res => {
      if (res.status === 200) {
        callback(true)
      } else {
        callback(false)
      }
    })
  }

  removeMember = (payload: RemoveMemberRequest, callback: RemoveMemberCallback) => {
    this.api({
      method: 'DELETE',
      url: `${routes.GROUP_CHATS}/${payload.groupId}/members/${payload.memberId}`
    }).then(res => {
      if (res.status === 200) {
        callback(true)
      } else {
        callback(false)
      }
    })
  }

  removeContact = (payload: RemoveContactRequest, callback: RemoveContactCallback) => {
    this.api({
      method: 'DELETE',
      url: `${routes.CONTACTS}/${payload.contactType}/${payload.contactId}`
    }).then(res => {
      if (res.status === 200) {
        callback(true)
      } else {
        callback(false)
      }
    })
  }

  saveSharedData = (payload: SaveSharedDataRequest, callback: SaveSharedDataCallback) => {
    this.api({
      method: 'POST',
      url: `${routes.ATTACHMENTS}`,
      data: {
        ...payload,
        kind: payload.kind,
        src: payload.src,
        dst: payload.dst,
        createdAt: payload.createdAt,
        fileName: payload.fileName,
        fileExtension: payload.fileExtension,
        fileUrl: payload.fileUrl
      }
    }).then(res => {
      if (res.status === 200) {
        callback(true)
      } else {
        callback(false)
      }
    })
  }

  leaveGroup = (payload: LeaveGroupRequest, callback: LeaveGroupCallback) => {
    this.api({
      method: 'DELETE',
      url: `${routes.GROUP_CHATS}/${payload.groupId}/members/${payload.memberId}`
    }).then(res => {
      if (res.status === 200) {
        callback(true)
      } else {
        callback(false)
      }
    })
  }

  //https://dev.digivox.com.br/unity-api/api/v1/     contacts/:contactType/:contactId/favorite/:favorite
  setFavoriteUser = (object: FavoriteUser, callback: FavoriteUserCallback) => {
    this.api({
      method: 'PUT',
      url: `${routes.CONTACTS}/${object.contactType}/${object.contactID}/favorite/${object.favorite}`
    }).then(res => {
      if (res.status === 200) {
        callback(true)
      } else {
        callback(false)
      }
    })
  }

  setDataUser = (object: UserDataChange, callback: UserDataChangeCallback) => {
    this.api({
      method: 'PUT',
      url: `${routes.ACCOUNT}`,
      data: { ...object, nickname: object.name }
    }).then(res => {
      if (res.status === 200) {
        callback(true)
      } else {
        callback(false)
      }
    })
  }

  setNewPassword = (object: UserPasswordChange, callback: UserPasswordChangeCallback) => {
    this.api({ method: 'PUT', url: `${routes.PASSWORD}`, data: { ...object } }).then(res => {
      if (res.status === 200 && res.data === 'SUCCESS') {
        callback(true)
        return
      }
      callback(false)
    })
  }

  favoriteMessage = (payload: FavoriteMessagePayload, callback: FavoriteMessageCallback) => {
    this.api({ method: 'POST', url: `${routes.FAVORITE_MESSAGES}`, data: { ...payload } }).then(
      res => {
        if (res.status === 200) {
          callback(true)
        } else {
          callback(false)
        }
      }
    )
  }

  deleteMessage = (payload: DeleteMessagePayload, callback: DeleteMessageCallback) => {
    this.api({ method: 'DELETE', url: `${routes.FAVORITE_MESSAGES}/${payload.messageId}` }).then(
      res => {
        if (res.status === 200) {
          callback(true)
        } else {
          callback(false)
        }
      }
    )
  }

  //v1/favorite-messages?limit=10&page=1&domain=b66a9cd3-ab34-4499-9587-a638d548d763&to=2001@labdev.digivox.com.br&contactType=contact&text=hello

  listFavoriteMessages = (options: ApiOptions, callback: ListFavoriteMessagesCallback) => {
    this.api({ method: 'GET', url: `${routes.FAVORITE_MESSAGES}`, params: options.paramsUrl }).then(
      res => {
        if (res.status === 200) {
          callback(res.data)
        }
      }
    )
  }

  changeGroupInfo = (object: ChangeGroupInfoRequest, callback: ChangeGroupInfoCallback) => {
    this.api({
      method: 'PUT',
      url: `${routes.GROUP_CHATS}/${object.groupId}`,
      data: {
        name: object.groupName,
        description: object.description
      }
    }).then(res => {
      if (res.status === 200) {
        callback(true)
      } else {
        callback(false)
      }
    })
  }

  getGroups = (options: ApiOptions, callback: GetGroupsCallback) => {
    this.api({ method: 'GET', url: routes.GROUP_CHATS, params: options.paramsUrl })
      .then(response => {
        try {
          const parsedGroups: Group[] = response.data.groups.map((group: any) => {
            return {
              id: group.id,
              name: group.groupName,
              description: group.description,
              domainId: group.domainId,
              domainName: group.domainName,
              imageUrl: group.imageUrl | group.urlAvatar,
              members: group.members.map((member: any) => {
                return {
                  id: member.id,
                  name: member.name,
                  imId: member.imId,
                  email: member.email,
                  admin: member.admin,
                  status: member.status,
                  extension: member.extension,
                  imageUrl: member.imageUrl
                }
              })
            }
          })
          callback({
            metadata: response.data.metadata,
            groups: parsedGroups
          })
        } catch (e) {
          callback(null, e)
        }
      })
      .catch(e => {
        callback(null, e)
      })
  }
  getGroup = (id: string, options: ApiOptions, callback: GetGroupCallback) => {
    this.api({ method: 'GET', url: `${routes.GROUP_CHATS}/${id}`, params: options.paramsUrl })
      .then((response: any) => {
        const parsedGroup: Group = {
          id: response.data.id,
          name: response.data.groupName,
          description: response.data.description,
          domainId: response.data.domainId,
          domainName: response.data.domainName,
          imageUrl: response.data.imageUrl || response.data.urlAvatar,
          members: response.data.members.map((member: any) => {
            return {
              id: member.id,
              name: member.name,
              imId: member.imId,
              email: member.email,
              admin: member.admin,
              status: member.status,
              extension: member.extension,
              imageUrl: member.imageUrl
            }
          })
        }
        callback(parsedGroup)
      })
      .catch(e => callback(null, e))
  }

  createGroup = (options: ApiOptions, callback: CreateGroupCallback) => {
    const params = this.parseParamsUrl(options.paramsUrl || {})
    params.append('resource', 'converse.js-88548647')
    this.api({
      method: 'POST',
      url: routes.GROUP_CHATS,
      data: JSON.stringify(options.body),
      params,
      headers: { 'content-type': 'application/json', accept: 'application/json' }
    })
      .then((group: any) => {
        callback(group.data)
      })
      .catch(e => callback(null, e))
  }
  getAttachments = (options: ApiOptions, callback: GetAttachmentsCallback) => {
    let params
    if (options.paramsUrl) params = this.parseParamsUrl(options.paramsUrl)
    this.api({
      method: 'GET',
      url: routes.ATTACHMENTS,
      params
    }).then(response => {
      callback({
        metadata: response.data.metadata,
        attachments: response.data.attachments
      })
    })
  }
  toggleMemberRole = (object: toggleMemberRoleRequest, callback: toggleMemberRoleCallback) => {
    this.api({
      method: 'PUT',
      url: `${routes.GROUP_CHATS}/${object.groupId}/members/${object.memberId}/admin/${object.admin}`
    }).then(res => {
      if (res.status === 200) {
        callback(true)
      } else {
        callback(false)
      }
    })
  }

  parseParamsUrl = (paramsUrl: ParamsURL) => {
    const params = new URLSearchParams()
    Object.entries(paramsUrl || {}).forEach(([key, v]) => {
      params.append(key, v)
    })
    return params
  }

  listConferenceRooms = (paramsUrl: ParamsURL, cb: GetConferencesCallback) => {
    let params
    if (paramsUrl) params = this.parseParamsUrl(paramsUrl)
    this.api({
      method: 'GET',
      url: routes.CONFERENCES,
      params
    }).then(response => {
      const res = response.data as Conferences
      cb(res)
    })
  }

  createConference = (
    body: CreateConferenceRequest,
    isEmailSender: boolean,
    cb: CreateConferenceCallback
  ) => {
    this.api({
      method: 'POST',
      url: routes.CONFERENCES,
      params: { isEmailSender },
      data: body
    }).then(res => {
      if (res.status >= 200 && res.status < 300) {
        cb(res.data)
      } else {
        cb(res.data)
      }
    })
  }

  updateConference = (
    conferenceId: string,
    body: CreateConferenceRequest,
    cb: UpdateConferenceCallback
  ) => {
    this.api({
      method: 'PUT',
      url: `${routes.CONFERENCES}/${conferenceId}`,
      data: body
    }).then(res => {
      if (res.status >= 200 && res.status < 300) {
        cb(true)
      } else {
        cb(false)
      }
    })
  }

  removeConference = (conferenceId: string, cb: RemoveConferenceCallback) => {
    this.api({
      method: 'DELETE',
      url: `${routes.CONFERENCES}/${conferenceId}`
    }).then(res => {
      if (res.status >= 200 && res.status < 300) {
        cb(true)
      } else {
        cb(false)
      }
    })
  }

  addConferenceParticipant = (
    conferenceId: string,
    data: ConferenceRoomParticipant[],
    cb: AddConferenceParticipantCallback
  ) => {
    this.api({
      method: 'POST',
      url: `${routes.CONFERENCE_PARTICIPANTS}/${conferenceId}`,
      data
    }).then(res => {
      if (res.status >= 200 && res.status < 300) {
        cb(true)
      } else {
        cb(false)
      }
    })
  }

  removeConferenceParticipant = (
    conferenceId: string,
    participantId: string,
    cb: RemoveConferenceParticipantCallback
  ) => {
    this.api({
      method: 'DELETE',
      url: `${routes.CONFERENCE_PARTICIPANTS}/${conferenceId}/${participantId}`
    }).then(res => {
      if (res.status >= 200 && res.status < 300) {
        cb(true)
      } else {
        cb(false)
      }
    })
  }
  updateParticipant = (
    conferenceId: string,
    participantId: string,
    data: ConferenceRoomParticipant[],
    cb: RemoveConferenceParticipantCallback
  ) => {
    this.api({
      method: 'DELETE',
      url: `${routes.CONFERENCE_PARTICIPANTS}/${conferenceId}/${participantId}`
    }).then(res => {
      if (res.status >= 200 && res.status < 300) {
        this.api({
          method: 'POST',
          url: `${routes.CONFERENCE_PARTICIPANTS}/${conferenceId}`,
          data
        }).then(res => {
          if (res.status >= 200 && res.status < 300) {
            cb(true)
          } else {
            cb(false)
          }
        })
        cb(false)
      } else {
        cb(false)
      }
    })
  }
  sendConferenceParticipantsEmail = (
    conferenceId: string,
    cb: SendConferenceParticipantEmailCallback
  ) => {
    this.api({
      method: 'GET',
      url: `${routes.CONFERENCE_PARTICIPANTS}/send-emails/${conferenceId}`
    }).then(res => {
      if (res.status >= 200 && res.status < 300) {
        cb(true)
      } else {
        cb(false)
      }
    })
  }
  getScheduleContacts = (options: ApiOptions, cb: GetScheduleContactsCallback) => {
    this.api({
      method: 'GET',
      url: `${routes.USERCONTACTS}`,
      params: options.paramsUrl ? this.parseParamsUrl(options.paramsUrl) : {},
      data: options.body ? options.body : {}
    }).then(res => {
      if (res.status >= 200 && res.status < 300) {
        cb(res.data)
      } else {
        cb(null)
      }
    })
  }
  createScheduleContact = (payload: ScheduleContactPayload, cb: CreateScheduleContactCallback) => {
    this.api({
      method: 'post',
      url: `${routes.USERCONTACTS}`,
      data: payload
    }).then(res => {
      if (res.status >= 200 && res.status < 300) {
        cb(res.data)
      } else {
        cb(null)
      }
    })
  }
  deleteScheduleContact = (id: string, cb: RemoveConferenceCallback) => {
    this.api({
      method: 'DELETE',
      url: `${routes.USERCONTACTS}/${id}`
    }).then(res => {
      if (res.status >= 200 && res.status < 300) {
        cb(true)
      } else {
        cb(false)
      }
    })
  }
  getCallHistory = (ramal: string, options: ApiOptions, cb: GetCallsHistoryCallback) => {
    this.api({
      method: 'GET',
      url: `${routes.CALLS_HISTORY}/${ramal}`,
      params: options.paramsUrl ? this.parseParamsUrl(options.paramsUrl) : {},
      data: options.body ? options.body : {}
    }).then(res => {
      if (res.status >= 200 && res.status < 300) {
        cb(res.data)
      } else {
        cb(null)
      }
    })
  }
  readMessage = (options: ApiOptions, cb: ReadMessageCallback) => {
    this.api({
      method: 'PUT',
      url: `${routes.MESSAGES_READ}/${options.paramsUrl?.chatType}/${options.paramsUrl?.between}/${options.paramsUrl?.id}`,
      headers: { 'content-type': 'application/json', accept: 'application/json' }
    }).then(res => {
      if (res.status >= 200 && res.status < 300) {
        cb(true)
      } else {
        cb(false)
      }
    })
  }
  getRamalInfo = (ramal: string, domain: string, cb: GetRamalInfoCallback) => {
    this.api({
      method: 'GET',
      url: `${routes.EXTENSION}/${ramal}/${domain}`
    }).then(res => {
      if (res.status >= 200 && res.status < 300) {
        cb(res.data)
      } else {
        cb(null)
      }
    })
  }
  getRamalFacilities = (ramal: string, options: ApiOptions, cb: GetRamalFacilitiesCallback) => {
    this.api({
      method: 'GET',
      url: `${routes.CALLS_FORWARDINGS}/${ramal}`,
      params: options.paramsUrl ? this.parseParamsUrl(options.paramsUrl) : {},
      data: options.body ? options.body : {}
    }).then(res => {
      if (res.status >= 200 && res.status < 300) {
        cb(res.data)
      } else {
        cb(null)
      }
    })
  }
  updateRamalFacilities = (
    ramal: string,
    data: RamalFacilities,
    cb: UpdateRamalFacilitiesCallback
  ) => {
    this.api({
      method: 'PUT',
      url: `${routes.CALLS_FORWARDINGS}/${ramal}`,
      data: data
    }).then(res => {
      if (res.status >= 200 && res.status < 300) {
        cb(true)
      } else {
        cb(false)
      }
    })
  }
  getGroupParticipants = (groupId: string, cb: GetGroupConferenceParticipants) => {
    this.api({
      method: 'GET',
      url: `${routes.CONFERENCES_GROUP_CHAT}/${groupId}`
    }).then(res => {
      if (res.status >= 200 && res.status < 300) {
        cb(res.data)
      } else {
        cb(null)
      }
    })
  }
  callGroupParticipants = (data: CallGroupConferenceParticipants, cb: (s: boolean) => void) => {
    this.api({
      method: 'POST',
      url: `${routes.CONFERENCES_CALL}?identify=true`,
      data
    }).then(res => {
      if (res.status >= 200 && res.status < 300) {
        cb(true)
      } else {
        cb(false)
      }
    })
  }
  changePicture = (body: { base64: string }, cb: changePictureCallback) => {
    this.api({
      method: 'PUT',
      url: `${routes.AVATAR}`,
      data: body
    })
  }
  getAppSettings = (deviceId: string | null, cb: (r: DeviceSettings | false) => void) => {
    this.api({
      method: 'GET',
      url: `${routes.APP_SETTINGS}${deviceId ? `/${deviceId}` : ''}`
    })
      .then(res => {
        if (res.status >= 200 && res.status < 300) {
          cb(res.data)
        } else {
          cb(false)
        }
      })
      .catch(e => {
        cb(false)
      })
  }
  putAppSettings = (deviceId: string, deviceSettings: DeviceSettings, cb: (r: boolean) => void) => {
    this.api({
      method: 'PUT',
      url: `${routes.APP_SETTINGS}/${deviceId}`,
      data: deviceSettings
    })
      .then(res => {
        if (res.status >= 200 && res.status < 300) {
          cb(true)
        } else {
          cb(false)
        }
      })
      .catch(e => {
        cb(false)
      })
  }
}
