import { ReactNotificationOptions, store } from "react-notifications-component";

export const sendNotification = (
  title: string,
  message: string,
  type: ReactNotificationOptions["type"],
  duration: number = 5000
) => {
  store.addNotification({
    title,
    message,
    type,
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: duration,
      onScreen: true,
    },
  });
};
