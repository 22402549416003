import React, { useState } from "react";
import {
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  Button,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQrcode, faKey } from "@fortawesome/free-solid-svg-icons";

import "./../assets/css/login.scss";

import BoxTitle from "../components/BoxTitle";
import BackgroundLogin from "../components/BackgroundLogin";
import { sendNotification } from "../util/notification";
import { useRecover } from "../contexts/RecoverContext";

const NewPassword: React.FC = () => {
  const { setNewPassword } = useRecover();
  const [otp, setOtp] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [confirmPassword, setConfirmPassword] = useState<string>();

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!otp) {
      sendNotification("Erro", "Digite o token enviado no E-mail!", "warning");
    } else if (!password) {
      sendNotification("Erro", "Digite uma nova senha!", "warning");
    } else if (!confirmPassword) {
      sendNotification("Erro", "Confirme a nova senha!", "warning");
    } else if (password !== confirmPassword) {
      sendNotification("Erro", "As senhas não são iguais!", "warning");
    } else {
      setNewPassword({
        otp,
        password,
      });
    }
  };
  return (
    <BackgroundLogin>
      <div className="row w-100">
        <div className="col-12 col-lg-6 d-flex justify-content-center justify-content-lg-end align-items-center">
          <div className="card card-login mr-lg-5">
            <div className="card-body m-3 m-lg-5">
              <div className="d-flex flex-column align-items-center">
                <div className="mb-4">
                  <img
                    style={{ maxWidth: "200px" }}
                    src="./logo.png"
                    alt="Logo"
                  />
                </div>
                <div className="mb-3 title">Recuperação de senha.</div>
                <div className="mb-4 sub-title">
                  Digite sua nova senha e confirme:
                </div>
              </div>
              <form onSubmit={onSubmit}>
                <div className="mb-3">
                  <InputGroup>
                    <Input
                      type="text"
                      placeholder="Token"
                      bsSize="lg"
                      className="input-login"
                      onChange={(e) => {
                        setOtp(e.target.value);
                      }}
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText className="input-group-text">
                        <FontAwesomeIcon
                          icon={faQrcode}
                          size="sm"
                          className="input-icon"
                        />
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </div>
                <div className="mb-4">
                  <InputGroup>
                    <Input
                      type="password"
                      placeholder="Nova senha"
                      bsSize="lg"
                      className="input-login"
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText className="input-group-text">
                        <FontAwesomeIcon
                          icon={faKey}
                          size="sm"
                          className="input-icon"
                        />
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </div>
                <div className="mb-4">
                  <InputGroup>
                    <Input
                      type="password"
                      placeholder="Confirmar nova senha"
                      bsSize="lg"
                      className="input-login"
                      onChange={(e) => {
                        setConfirmPassword(e.target.value);
                      }}
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText className="input-group-text">
                        <FontAwesomeIcon
                          icon={faKey}
                          size="sm"
                          className="input-icon"
                        />
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </div>
                <div>
                  <Button
                    type="submit"
                    size="lg"
                    block
                    className="buttom-login"
                  >
                    Recuperar
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <BoxTitle />
      </div>
    </BackgroundLogin>
  );
};

export default NewPassword;
