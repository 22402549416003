import React from "react";

const BoxTitle: React.FC = () => {
  return (
    <div className="has-shown-login-box col-12 col-lg-6 d-flex justify-content-start align-items-end">
      <div className="box-content ml-lg-2 mb-5 d-none d-lg-block text-reveal-fast">
          <div className="d-flex justify-content-center align-items-center sub-box pt-2 text-reveal-fast faded">
            <div>Conferência a qualquer hora</div>
          </div>
          <div className="d-flex justify-content-center align-items-center sub-box pb-2 text-reveal-slow faded">
            <div>de qualquer lugar</div>
          </div>
      </div>
    </div>
  );
};

export default BoxTitle;
