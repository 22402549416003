import React, { createContext, useContext, useEffect, useState } from "react";
import API from "@digivox/apicommunicator";
import { sendNotification } from "../util/notification";
import { useHistory } from "react-router-dom";

interface Callback {
  (...props: any): any;
}

interface INewPassword {
  otp: string;
  password: string;
}

interface IRecoverContextData {
  recoverUser: string | undefined;
  setRecoverUser(value: string | undefined): void;
  newPassword: INewPassword | undefined;
  setNewPassword(value: INewPassword | undefined): void;
}

export const RecoverContext = createContext<IRecoverContextData>(
  {} as IRecoverContextData
);

export const RecoverProvider: React.FC = ({ children }) => {
  const [recoverUser, setRecoverUser] = useState<string | undefined>();
  const [apiUrl, setApiUrl] = useState<string | undefined>();
  const [newPassword, setNewPassword] = useState<INewPassword | undefined>();
  const history = useHistory();

  const onRecoverPassword = () => {
    if (recoverUser) {
      ;(API as any).sendRecoverEmail(recoverUser)
        .then((res) => {
          if (res) {
            sendNotification("Sucesso", "E-mail Enviado!", "success", 3000);
            setApiUrl(res);
            setTimeout(() => {
              history.push("/update-password");
            }, 3000);
          }
        })
        .catch((err) => {
          clearStates();
          sendNotification("Error", err.message, "danger");
        });
	}
  };
  const onNewPassword = () => {
    if (newPassword && apiUrl) {
      ;(API as any).updatePassword(apiUrl, newPassword.otp, newPassword.password)
        .then((res) => {
          if (res) {
            sendNotification("Sucesso", "Senha Alterada!", "success", 2000);
            clearStates();
            setTimeout(() => {
              history.push("/");
            }, 2000);
          }
        })
        .catch((err) => {
          setNewPassword(undefined);
          sendNotification("Error", err.message, "danger");
        });
    }
  };
  const clearStates = () => {
    setRecoverUser(undefined);
    setNewPassword(undefined);
    setApiUrl(undefined);
  };
  useEffect(onRecoverPassword, [recoverUser]);
  useEffect(onNewPassword, [newPassword]);
  return (
    <RecoverContext.Provider
      value={{
        recoverUser,
        setRecoverUser,
        newPassword,
        setNewPassword,
      }}
    >
      {children}
    </RecoverContext.Provider>
  );
};

export function useRecover() {
  return useContext(RecoverContext);
}
