export const DPS = 'https://dps.digivox.com.br/api'
export const OAUTH = 'oauth2/authorize'
export const SETTINGS = 'account/settings'
export const APP_SETTINGS = 'app/settings'
export const ACCOUNT = 'account/user-data'
export const CHATS = 'chats'
export const PASSWORD = 'users/update-password'
export const GROUP_CHATS = 'group-chats'
export const CONTACTS = 'contacts'
export const CONFERENCES = 'conference-rooms'
export const CONFERENCE_PARTICIPANTS = 'participants'
export const MESSAGES = 'chats/messages'
export const MESSAGES_READ = 'chats/messages/read'
export const ATTACHMENTS = 'attachments'
export const RECOVER = 'account/password/recovery'
export const UPDATE_PASSWORD = 'account/password'
export const USERCONTACTS = 'user-contacts'
export const CALLS_HISTORY = 'calls-history'
export const EXTENSION = 'extensions'
export const CALLS_FORWARDINGS = 'call-forwardings'
export const FAVORITE_MESSAGES = 'favorite-messages'
export const READ = 'read'
export const CONFERENCES_CALL = 'conferences'
export const CONFERENCES_GROUP_CHAT = 'conferences/group-chat'
export const AVATAR = 'avatars'
