import React, { createContext, useContext, useEffect, useState } from "react";
import API from "@digivox/apicommunicator";
import { sendNotification } from "../util/notification";
interface Callback {
  (...props: any): any;
}
enum Routes {
  SQUAD = "squad-v2",
  SOFTPHONE = "sp-v2",
}
interface IUser {
  login: string;
  password: string;
  save: boolean;
}
interface IAuthData {
  access_token: string;
  baseUrl: string;
  email: string;
  expires_in: number;
  user_id: string;
  user_name: string;
}
interface ILoginContextData {
  loginUser: IUser | undefined;
  setLoginUser(value: IUser): void;
  loading: boolean;
}

export const LoginContext = createContext<ILoginContextData>(
  {} as ILoginContextData
);

export const LoginProvider: React.FC = ({ children }) => {
  const [loginUser, setLoginUser] = useState<IUser | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const checkPermissions = (api: API, cb: Callback) => {
    api.getSettings({}, (settings) => {
      if (settings?.chat === "YES" && settings.video === "YES") {
        cb(Routes.SQUAD);
      } else if (settings?.chat === "NO" && settings.video === "YES") {
        cb(Routes.SOFTPHONE);
      } else {
        cb(undefined);
      }
    });
  };
  const onLogin = () => {
    if (loginUser) {
      setLoading(true);
      API.auth(loginUser)
        .then((response) => {
          if (response) {
            const api = new API({
              apiUrl: response.baseUrl,
              token: response.access_token,
            });
            checkPermissions(api, (route) => {
              if (!route) {
                sendNotification(
                  "Erro",
                  "Usuario não tem permissão de chat ou voz",
                  "danger"
                );
                return;
              }
              const authData = {
                ...response,
                email: loginUser.login,
              };
              if (loginUser.save) {
                localStorage.setItem("authorization", JSON.stringify(authData));
              } else {
                sessionStorage.setItem(
                  "authorization",
                  JSON.stringify(authData)
                );
              }
              // eslint-disable-next-line no-restricted-globals
              location.href = `//${location.hostname}/${route}/`; // keep protocol and end-slash for electron app
            });
          }
        })
        .catch((e) => {
          sendNotification("Erro", e.message, "danger");
          setLoading(false);
        });
    }
  };
  const checkisLoggedIn = () => {
    const authString =
      localStorage.getItem("authorization") ||
      sessionStorage.getItem("authorization");
    if (authString) {
      setLoading(true);
      const auth: IAuthData = JSON.parse(authString);
      const api = new API({
        apiUrl: auth.baseUrl,
        token: auth.access_token,
      });
      checkPermissions(api, (route) => {
        if (!route) {
          localStorage.removeItem("authorization");
          sessionStorage.removeItem("authorization");
          sendNotification(
            "Erro",
            "Sua sessão expirou! Por favor, entre novamente!",
            "danger"
          );
          setLoading(false);
          return;
        }
        // eslint-disable-next-line no-restricted-globals
        location.href = `https://${location.hostname}/${route}/`;
      });
    }
  };
  useEffect(onLogin, [loginUser]);
  useEffect(checkisLoggedIn, []);
  return (
    <LoginContext.Provider
      value={{
        loginUser,
        setLoginUser,
        loading,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};

export function useLogin() {
  return useContext(LoginContext);
}
