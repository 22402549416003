export function validateEmail(email: string) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}
export function parseMsg(message: any) {
  return {
    idXmpp: message.idXmpp,
    to: message.to,
    toDomain: message.toDomain,
    from: message.from,
    fromDomain: message.fromDomain,
    message: message.message,
    sentAt: message.sentAt,
    chatType: message.chatType,
    readStatus: message.readStatus,
    deliveryStatus: message.deliveryStatus,
    readTimestamp: message.readTimestamp,
    deliveryTimestamp: message.deliveryTimestamp,
    contentType: message.contentType,
    content: message.content,
    fromName: message.fromName,
    ...messageIsFile(message)
  }
}
export function messageIsFile(message: any) {
  const splitedMessage = message.message
    .replace('https://', '')
    .replace('http://', '')
    .split('/')
  const lastSplit = splitedMessage[splitedMessage.length - 1]
  const returnBooleans = {
    isImage:
      /\.(jpe?g|png|gif|ico)$/i.test(message.message) && message.message.includes('/upload/'),
    isAudio:
      /\.(ogg|mp3|wav|m4a|webm)$/i.test(message.message) && message.message.includes('/upload/'),
    isVideo: /\.(mp4|avi)$/i.test(message.message) && message.message.includes('/upload/'),
    isFile:
      splitedMessage.length > 1 &&
      lastSplit.includes('.') &&
      (message.message.indexOf('http://') === 0 ||
        (message.message.indexOf('https://') === 0 && message.message.includes('/upload/')))
  }
  if (
    !returnBooleans.isFile &&
    (returnBooleans.isImage || returnBooleans.isAudio || returnBooleans.isVideo)
  ) {
    returnBooleans.isFile = true
  }
  return returnBooleans
}
