import React from "react";

import "./../assets/css/login.scss";
import Object1 from "./../assets/img/obj1.png";
import Object2 from "./../assets/img/obj2.png";

const BackgroundLogin: React.FC = ({ children }) => {
  return (
    <div className="background-image">
      <div className="page-login">
          <img src={Object1} alt="obj" className="object1 has-shown-white-points" />
          <img src={Object2} alt="obj" className="object2 has-shown-blue-points" />
          {children}
      </div>
    </div>
  );
};

export default BackgroundLogin;
