import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/app.scss";

ReactDOM.render(
  <React.StrictMode>
    <ReactNotification />
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
